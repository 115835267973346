.day-card {
  margin-top: 1em;
}

.expander-symbol {
  transform: rotate(-90deg);
  font-size: 1.5em;
}

.collapsed .expander-symbol {
  transform: rotate(90deg);
}
